import { useCallback, useMemo, useState } from "react"

const dataOwner =
  "TRUST INVESTMENT SPÓŁKA AKCYJNA, ul. Robotnicza 1, 25-662 Kielce NIP: 6631877218, REGON: 381244959, KRS: 0000747790 (Administrator danych)"

const legalsKeys = {
  marketing: "marketing",
  mail: "mail",
  phone: "phone",
  statement: "statement",
  sms: "sms",
}

const templates = {
  [legalsKeys[
    "marketing"
  ]]: `ZGODA_MARKETING (Wyrażam zgodę na przetwarzanie przez ${dataOwner}, moich danych osobowych zawartych w niniejszym formularzu kontaktowym w celu i zakresie koniecznym do realizacji zgłoszenia.)`,
  [legalsKeys[
    "mail"
  ]]: `ZGODA_MAIL (Wyrażam zgodę na przetwarzanie przez ${dataOwner}, moich danych osobowych zawartych w niniejszym formularzu kontaktowym w celu przesyłania mi ofert handlowych na produkty własne spółki drogą elektroniczną.)`,
  [legalsKeys[
    "phone"
  ]]: `ZGODA_TEL (Wyrażam zgodę na przetwarzanie przez ${dataOwner}, moich danych osobowych zawartych w niniejszym formularzu kontaktowym w celu kontaktu telefonicznego ze strony przedstawicieli spółki w sprawach związanych z ofertą handlową na produkty własne.)`,
  [legalsKeys[
    "statement"
  ]]: `ZGODA_OSWIADCZENIE (Administratorem Państwa danych osobowych jest firma ${dataOwner}. Dane wpisane w formularzu kontaktowym będą przetwarzane w celu udzielenia odpowiedzi na przesłane zapytanie zgodnie z Polityką Prywatności.)`,
  [legalsKeys[
    "sms"
  ]]: `ZGODA_SMS (Wyrażam zgodę na przetwarzanie przez ${dataOwner}, moich danych osobowych zawartych w niniejszym formularzu kontaktowym w celu przesyłania mi ofert handlowych na produkty własne spółki drogą SMS.)`,
}

const useVoxLegals = () => {
  const [legals, setLegals] = useState(
    Object.fromEntries(
      Object.entries(legalsKeys).map(([_, value]) => [value, ""])
    )
  )

  const keys = useMemo(
    () => Object.entries(legalsKeys).map(([_, value]) => value),
    []
  )

  const handleLegal = useCallback(
    (type, selected) =>
      setLegals(prevLegals => ({
        ...prevLegals,
        [type]: selected ? templates[type] : "",
      })),
    []
  )

  const selectLegal = useCallback(
    (types, selected) => {
      if (!types) return null

      if (typeof types === "string" && keys.some(key => key === types))
        handleLegal(types, selected)

      if (Array.isArray(types)) {
        types.forEach(type => {
          if (keys.some(key => key === type)) {
            handleLegal(type, selected)
          }
        })
      }
    },
    [keys, handleLegal]
  )

  const acceptedLegals = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(legals)
          .map(([key, value]) =>
            value !== "" ? [`${key}Legal`, value] : undefined
          )
          .filter(item => item)
      ),
    [legals]
  )

  return {
    selectLegal,
    acceptedLegals:
      Object.keys(acceptedLegals).length > 0 ? acceptedLegals : null,
  }
}

export default useVoxLegals
