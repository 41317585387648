import { useState } from "react"

const OFFSET = 60

const useImperativeRedirect = () => {
  const [screenPosition, setScreenPosition] = useState(null)

  const mouseEvents = url => ({
    onMouseDown: url
      ? e => {
          const { screenX, screenY } = e

          setScreenPosition({ x: screenX, y: screenY })
        }
      : undefined,
    onMouseUp: url
      ? e => {
          const { screenX, screenY } = e

          const x_diff = screenX - screenPosition.x
          const y_diff = screenY - screenPosition.y

          if (
            x_diff <= OFFSET &&
            x_diff >= OFFSET * -1 &&
            y_diff <= OFFSET &&
            y_diff >= OFFSET * -1
          ) {
            window.location.href = url
          }
        }
      : undefined,
  })

  return { mouseEvents }
}

export default useImperativeRedirect
